/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  DownCaret,
  SmartTourIcon,
  CostSegIcon,
  SpecsExperienceIcon,
  AboutMadisonSpecsIcon,
  MeetYourTeamIcon,
  CaseStudiesIcon,
  OurFootprintIcon,
  FAQsIcon,
  GlossaryIcon,
} from '../ui/icons';
import CallButton from '../ui/CallButton';
import SiteLink from './SiteLink';
import TransitionModal from './TransitionModal';
import { wrapS, menuS, sectionWrap, itemDropdownWrap, quoteButton, myCircle } from './headerStyles';

export default class Header extends React.Component {
  state = {
    showExitModal: false,
    exitLink: '',
  };

  onLinkClick = (location) => this.setState({ showExitModal: true, exitLink: location });
  render() {
    const { showExitModal, exitLink } = this.state;
    return (
      <div css={wrapS}>
        {showExitModal && <TransitionModal onClick={() => (window.location.href = exitLink)} />}
        <NavLink to='/'>
          <img
            src='https://res.cloudinary.com/dyuuf6gor/image/upload/v1581088329/specs-svg/logo-top_wmmd5x.svg'
            alt='site logo'
            className='site-logo-image'
          />
        </NavLink>
        <div>
          <ul css={menuS}>
            <li>
              <Section sectionName='How It Works' height='240px'>
                <NavLink to='/about-cost-seg'>
                  <div className='wrapper'>
                    <div>
                      <CostSegIcon />
                    </div>
                    <div className='text'>
                      <div>What is Cost Seg</div>
                      <div>Learn more about the IRS-approved tax bill reduction</div>
                    </div>
                  </div>
                </NavLink>
                <NavLink to='/process'>
                  <div className='wrapper'>
                    <div>
                      <SpecsExperienceIcon />
                    </div>
                    <div className='text'>
                      <div>Your Specs Experience</div>
                      <div>What will your cost seg journey look like?</div>
                    </div>
                  </div>
                </NavLink>
              </Section>
            </li>
            <li>
              <Section sectionName='Who We Are' height='240px'>
                <SiteLink onLinkClick={this.onLinkClick} to='aboutus/overview'>
                  <div className='wrapper'>
                    <div>
                      <AboutMadisonSpecsIcon />
                    </div>
                    <div className='text'>
                      <div>About Madison Specs</div>
                      <div>Our mission, our values, and our foundation</div>
                    </div>
                  </div>
                </SiteLink>
                <SiteLink onLinkClick={this.onLinkClick} to='aboutus/personnel'>
                  <div className='wrapper'>
                    <div>
                      <MeetYourTeamIcon />
                    </div>
                    <div className='text'>
                      <div>Meet your team</div>
                      <div>Get to know your experts at SPECS</div>
                    </div>
                  </div>
                </SiteLink>
              </Section>
            </li>
            <li>
              <Section sectionName="What We've Done" height='240px'>
                <NavLink to='/case-studies/multi-family'>
                  <div className='wrapper'>
                    <div>
                      <CaseStudiesIcon />
                    </div>
                    <div className='text'>
                      <div>Case Studies </div>
                      <div>How much did a property like yours save?</div>
                    </div>
                  </div>
                </NavLink>
                <NavLink to='/map'>
                  <div className='wrapper'>
                    <div>
                      <OurFootprintIcon />
                    </div>
                    <div className='text'>
                      <div>Our Footprint </div>
                      <div>Our studies span the country</div>
                    </div>
                  </div>
                </NavLink>
              </Section>
            </li>
            <li>
              <Section sectionName='Help Hub' height='240px'>
                <NavLink to='/faq'>
                  <div className='wrapper'>
                    <div>
                      <FAQsIcon />
                    </div>
                    <div className='text'>
                      <div>FAQs </div>
                      <div>Get your answers here</div>
                    </div>
                  </div>
                </NavLink>
                <NavLink to='/glossary'>
                  <div className='wrapper'>
                    <div css={myCircle}>
                      <GlossaryIcon />
                    </div>
                    <div className='text'>
                      <div>Glossary</div>
                      <div>Know your terms, understand the process</div>
                    </div>
                  </div>
                </NavLink>
                {/* <SiteLink
                  onLinkClick={this.onLinkClick}
                  to="ourapproach/whyspecs"
                >
                  Why Specs
                </SiteLink>
                <a href="/#savings-scroll">Eligibility</a>{" "}
                <SiteLink
                  onLinkClick={this.onLinkClick}
                  to="resources/costsegregationglossarya"
                >
                  Cost Seg Glossary
                </SiteLink> */}
              </Section>
            </li>
            <li className='dif aic'>
              <HalfSection sectionName='Contact Us' link='/contact-us' />
            </li>
            <CallButton text='Get A Quote' to='/custom-quote' secondary styles={quoteButton} />
          </ul>
        </div>
      </div>
    );
  }
}

export function Section({ sectionName, children, height }) {
  return (
    <div css={[itemDropdownWrap, { '&:hover': { '& .menu-dropdown_wrap': { height: height } } }]}>
      <div css={sectionWrap} className='dropdown-header'>
        <div>{sectionName}</div>
        <DownCaret className='arrow-icon' />
      </div>
      <div className='menu-dropdown_wrap'>
        <div className='df col ubuntu fs-12'>{children}</div>
      </div>
    </div>
  );
}
function HalfSection({ sectionName, link }) {
  return (
    <div
      css={[
        sectionWrap,
        {
          '&:hover': {
            backgroundColor: '#fbfbfb',
            borderRadius: 8,
            cursor: 'pointer',
          },
        },
      ]}
    >
      <NavLink className='ubuntu allcaps' to={link}>
        {sectionName}
      </NavLink>
    </div>
  );
}
