/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { PureComponent } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer } from 'recharts';

export default class Graph extends PureComponent {
  render() {
    const { study } = this.props;
    const { yearOne, yearTwo, yearThree, yearFour, yearFive, yearSix, graphImg } = study;
    const data = [
      {
        name: 'Year 1',
        before: yearOne.before || '',
        after: yearOne.after || '',
      },
      {
        name: 'Year 2',
        before: yearTwo.before || '',
        after: yearTwo.after || '',
      },
      {
        name: 'Year 3',
        before: yearThree.before || '',
        after: yearThree.after || '',
      },
      {
        name: 'Year 4',
        before: yearFour.before || '',
        after: yearFour.after || '',
      },
      {
        name: 'Year 5',
        before: yearFive.before || '',
        after: yearFive.after || '',
      },
      {
        name: 'Year 6',
        before: yearSix.before || '',
        after: yearSix.after || '',
      },
    ];
    return (
      <div css={graphWrap}>
        {graphImg ? (
          <div>
            <img src={graphImg} alt='graph' style={{ width: '100%', height: '100%' }} />
          </div>
        ) : (
          <ResponsiveContainer width='100%' height='100%'>
            <BarChart
              // width={'100%'}
              height={325}
              data={data}
              barGap='12'
              margin={{
                top: 25,
                right: 25,
                left: 25,
                bottom: 25,
              }}
            >
              <CartesianGrid vertical={false} />
              <XAxis dataKey='name' />
              <YAxis padding={{ top: 46 }} tickFormatter={Convert} />
              <Legend
                width={250}
                wrapperStyle={{
                  bottom: 0,
                  backgroundColor: 'transparent',
                  left: '50%',
                  transform: 'translateX(-50%)',
                }}
              />
              <Bar
                legendType='circle'
                barSize={12}
                dataKey='before'
                fill='#55DCAC'
                radius={[6, 6, 6, 6]}
              />
              <Bar
                legendType='circle'
                barSize={12}
                dataKey='after'
                fill='#0827C7'
                radius={[6, 6, 6, 6]}
              />
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    );
  }
}

function Convert(number) {
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
  return formatter.format(number);
}

const graphWrap = {
  height: 360,
  maxWidth: 480,
  '.recharts-cartesian-grid-horizontal': {
    line: { stroke: '#E9E9E9' },
  },
  '.recharts-cartesian-axis.recharts-yAxis, .recharts-cartesian-axis-tick.recharts-yAxis': {
    line: { stroke: 'transparent' },
    text: { stroke: '#6C6C6C', fontSize: 12, letterSpacing: '0.18em', fontWeight: 100 },
  },
  '.recharts-cartesian-axis.recharts-xAxis': {
    line: { stroke: '#E9E9E9' },
    '.recharts-cartesian-axis-tick': {
      line: { stroke: 'transparent' },
      text: {
        stroke: '#C1C1C1',
        letterSpacing: '0.18em',
        fontSize: 10,
        fontWeight: 100,
        textTransform: 'uppercase',
      },
    },
  },
  // '.recharts-cartesian-grid-horizontal line:last-of-type': {
  //   display: 'none',
  // },
  '.recharts-default-legend': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textTransform: 'capitalize',
    li: {
      display: 'flex !important',
      alignItems: 'center',
      svg: { marginRight: '20px !important', height: 8, width: 8 },
      span: {
        color: 'black !important',
        fontSize: 16,
      },
    },
  },
};
