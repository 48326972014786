import React, { useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { Global } from '@emotion/core';
import { PatternFormat } from 'react-number-format';

import {
  SubmitButton,
  StepHeader,
  IndividualStepWrap,
  PreviousNextWrap,
  QuestionCircle,
  AddContactModal,
  AddContactSection,
  AddContactButtonWrap,
  DeleteIcon,
  EditIcon,
} from './styles';
import { allStates } from './mockData';
import { XIcon } from '../ui/icons';
import AddressAutocomplete from './AddressAutocomplete';

export default function ContactInformation({ request, setRequest, nextStep }) {
  const [addingContact, setAddingContact] = useState(false);
  const [additionalContact, setAdditionalContact] = useState({});
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [address, setAddress] = useState();

  const titleOptions = [
    { value: 'Mr', label: 'Mr.' },
    { value: 'Mrs', label: 'Mrs.' },
    { value: 'Dr', label: 'Dr.' },
    { value: 'Ms', label: 'Ms.' },
  ];

  const handleFieldChange = (event) => {
    let { name, value } = event.target;
    setRequest((draft) => {
      if (draft.contactInfo === undefined) {
        draft.contactInfo = {};
      }
      if (value === '') {
        value = null;
      }
      draft.contactInfo[name] = value;
    });
  };

  const handlePhoneChange = (values) => {
    const { floatValue } = values;
    setRequest((draft) => {
      if (draft.contactInfo === undefined) {
        draft.contactInfo = {};
      }
      draft.contactInfo.phone = floatValue;
    });
  };

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const validateEmail = (event) => {
    var isValid = event.target.value.toLowerCase().match(emailRegex);
    setIsEmailValid(isValid);
  };

  useEffect(() => {
    if (address == null) return;
    setRequest((draft) => {
      if (draft.contactInfo === undefined) {
        draft.contactInfo = {};
      }
      draft.contactInfo.addressLine1 = address.addressLine1;
      draft.contactInfo.city = address.city;
      draft.contactInfo.state = address.state;
      draft.contactInfo.zip = address.zip;
    });
  }, [address]);

  const onAdditionalContactFieldUpdate = (event) => {
    const { name, value } = event.target;
    setAdditionalContact({ ...additionalContact, [name]: value });
  };

  const handleSaveAdditionalContact = () => {
    setRequest((draft) => {
      draft.contactInfo = draft.contactInfo || {};
      draft.contactInfo.additionalContacts = draft.contactInfo.additionalContacts || [];

      if (additionalContact.index !== undefined) {
        // Editing an existing contact
        draft.contactInfo.additionalContacts = draft.contactInfo.additionalContacts.map((c, i) => {
          if (i === additionalContact.index) {
            return additionalContact;
          } else {
            return c;
          }
        });
      } else {
        // Adding a new contact
        draft.contactInfo.additionalContacts.push(additionalContact);
      }
    });
    setAdditionalContact({});
  };

  const deleteAdditionalContact = (index) => {
    setRequest((draft) => {
      draft.contactInfo.additionalContacts = draft.contactInfo.additionalContacts.filter(
        (_, i) => i !== index,
      );
    });
  };

  return (
    <IndividualStepWrap>
      <StepHeader>Contact Information</StepHeader>
      <div className='section_header'>Personal Information</div>
      <Grid container spacing={{ xs: 2, sm: 2.5 }}>
        <Grid item xs={12} sm={2}>
          <FormControl fullWidth size='small'>
            <InputLabel>Title</InputLabel>
            <Select
              className='select_'
              value={request?.contactInfo?.title ?? ''}
              label='Title'
              name='title'
              onChange={handleFieldChange}
              size='small'
            >
              {titleOptions.map((title) => (
                <MenuItem key={title.value} value={title.value}>
                  {title.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={5}>
          <TextField
            label='First Name'
            size='small'
            value={request?.contactInfo?.firstName ?? ''}
            name='firstName'
            onChange={handleFieldChange}
          />
        </Grid>
        <Grid item xs={6} sm={5}>
          <TextField
            label='Last Name'
            size='small'
            value={request?.contactInfo?.lastName ?? ''}
            name='lastName'
            onChange={handleFieldChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label='Company Name'
            size='small'
            value={request?.contactInfo?.company ?? ''}
            name='company'
            onChange={handleFieldChange}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextField
            error={!isEmailValid}
            label='Email *'
            size='small'
            value={request?.contactInfo?.email ?? ''}
            name='email'
            onChange={handleFieldChange}
            onBlur={validateEmail}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <PatternFormat
            label='Phone'
            size='small'
            value={request?.contactInfo?.phone ?? ''}
            name='phone'
            onValueChange={handlePhoneChange}
            format='(###) ### ####'
            customInput={TextField}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={2}>
          <TextField
            label='Ext'
            size='small'
            value={request?.contactInfo?.extension ?? ''}
            name='extension'
            onChange={handleFieldChange}
          />
        </Grid>
      </Grid>
      {request.contactInfo?.additionalContacts?.length > 0 ? (
        <AddContactSection hasContact={true}>
          <div className='contact-header'>Additional Contacts</div>
          <div>
            {request.contactInfo?.additionalContacts?.map((contact, index) => (
              <div key={index} className='contact_row'>
                <div>
                  {[
                    titleOptions.find((t) => contact.title === t.value)?.label,
                    contact.firstName,
                    contact.lastName,
                  ].join(' ')}
                </div>
                <div>{contact.email}</div>
                <div className='jcsb dif gap1 aic'>
                  <div
                    onClick={() => {
                      setAdditionalContact({ ...contact, index: index });
                      setAddingContact(true);
                    }}
                  >
                    <EditIcon />
                  </div>
                  <div onClick={() => deleteAdditionalContact(index)}>
                    <DeleteIcon />
                  </div>
                </div>
              </div>
            ))}
          </div>
          {request.contactInfo?.additionalContacts?.length < 5 && (
            <AddContactButton addContactClick={() => setAddingContact(true)} />
          )}
        </AddContactSection>
      ) : (
        <AddContactButton addContactClick={() => setAddingContact(true)} />
      )}
      {addingContact && (
        <AddContactModal>
          <Global
            styles={{
              ' body': {
                overflow: 'hidden',
                height: '100vh',
              },
            }}
          />
          <div>
            <div className='section_header'>Additional Point of Contact</div>
            <XIcon
              className='close_icon'
              onClick={() => {
                setAdditionalContact({});
                setAddingContact(false);
              }}
            />
            <Grid container spacing={{ xs: 2, sm: 3 }}>
              <Grid item xs={12}>
                <FormControl fullWidth size='small'>
                  <InputLabel>Title</InputLabel>
                  <Select
                    className='select_'
                    value={additionalContact.title ?? ''}
                    label='Title'
                    name='title'
                    onChange={onAdditionalContactFieldUpdate}
                    size='small'
                  >
                    {titleOptions.map((title) => (
                      <MenuItem key={title.value} value={title.value}>
                        {title.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={additionalContact.firstName ?? ''}
                  label='First Name'
                  size='small'
                  name='firstName'
                  onChange={onAdditionalContactFieldUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={additionalContact.lastName ?? ''}
                  label='Last Name'
                  size='small'
                  name='lastName'
                  onChange={onAdditionalContactFieldUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={additionalContact.email ?? ''}
                  label='Email *'
                  size='small'
                  name='email'
                  onChange={onAdditionalContactFieldUpdate}
                />
              </Grid>
            </Grid>
            <SubmitButton
              disabled={!additionalContact.email?.toLowerCase().match(emailRegex)}
              text='Save'
              noArrow
              onClick={() => {
                handleSaveAdditionalContact();
                setAddingContact(false);
              }}
            />
          </div>
        </AddContactModal>
      )}
      <div>
        <div className='section_header'>Business Address</div>
        <Grid container spacing={{ xs: 2, sm: 3 }}>
          <Grid item xs={12}>
            <AddressAutocomplete
              setAddress={setAddress}
              onNoResultsFound={handleFieldChange}
              label='Address Line 1'
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Address Line 2'
              size='small'
              value={request?.contactInfo?.addressLine2 ?? ''}
              name='addressLine2'
              onChange={handleFieldChange}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <TextField
              label='City'
              size='small'
              value={request?.contactInfo?.city ?? ''}
              name='city'
              onChange={handleFieldChange}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <FormControl fullWidth size='small'>
              <InputLabel>State</InputLabel>
              <Select
                className='select_'
                value={request.contactInfo?.state ?? ''}
                label='State'
                name='state'
                onChange={handleFieldChange}
                size='small'
              >
                {allStates.map((state) => (
                  <MenuItem key={state.value} value={state.value}>
                    {state.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={4}>
            <TextField
              label='Zip Code'
              size='small'
              value={request?.contactInfo?.zip ?? ''}
              name='zip'
              onChange={handleFieldChange}
            />
          </Grid>
        </Grid>
        <PreviousNextWrap>
          <div></div>
          <SubmitButton
            disabled={!request?.contactInfo?.email || !isEmailValid}
            text='Continue'
            onClick={nextStep}
          />
        </PreviousNextWrap>
      </div>
    </IndividualStepWrap>
  );
}

function AddContactButton({ addContactClick }) {
  return (
    <AddContactButtonWrap className='add_contact'>
      <span onClick={addContactClick}>+ Additional Points of Contact</span>
      <div className='dif aic relative jcc'>
        <QuestionCircle className='question_mark' />
        <div className='tooltip'>
          Contacts added here will receive notifications and updates on the study
        </div>
      </div>
    </AddContactButtonWrap>
  );
}
