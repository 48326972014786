/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { SpecsLogo, DownArrow } from '../ui/icons';
import {
  MainWrap,
  FooterWrap,
  SectionWrap,
  MenuSection,
  LogoWrap,
  FinePrintWrap,
  FinePrintInner,
  MenuItemWrap,
  InnerItemWrap,
  Copyright,
} from './mobileFooterStyles';

function Link({ to, children }) {
  return <a href={`https://www.madisonspecs.com/${to}.asp.html`}>{children}</a>;
}

export default class Footer extends React.Component {
  state = {
    menuExpandedStates: [false, false, false, false, false],
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.mobileMenuOpen !== this.props.mobileMenuOpen) {
      const { menuExpandedStates } = this.state;
      this.setState({ menuExpandedStates: menuExpandedStates.map((_) => false) });
    }
  };

  toggleMenuExpansion = (index) => {
    let { menuExpandedStates } = this.state;
    if (menuExpandedStates[index]) {
      //if one being clicked is already open, set all to false so that it gets closed
      menuExpandedStates = menuExpandedStates.map((_) => false);
    } else {
      //if a different one is being clicked, set only THAT one to be true so that all others close
      menuExpandedStates = this.state.menuExpandedStates.map((_, i) => i === index);
    }
    this.setState({ menuExpandedStates });
  };
  render() {
    const [oneExpanded, twoExpanded, threeExpanded, fourExpanded] = this.state.menuExpandedStates;
    const year = new Date().getFullYear();
    return (
      <MainWrap>
        <FooterWrap>
          <LogoWrap>
            <SpecsLogo />
          </LogoWrap>
          <div>
            <MenuSection>
              <div>
                <Section
                  sectionName='How it works'
                  isOpen={oneExpanded}
                  handleOpen={() => this.toggleMenuExpansion(0)}
                  height='96px'
                >
                  <NavLink to='/about-cost-seg'>What is Cost Seg</NavLink>
                  <NavLink to='/process'>Your SPECS Experience</NavLink>
                </Section>
              </div>
              <div>
                <Section
                  sectionName='Who We Are'
                  isOpen={twoExpanded}
                  handleOpen={() => this.toggleMenuExpansion(1)}
                  height='96px'
                >
                  <Link to='aboutus/overview'>About Madison SPECS</Link>
                  <Link to='aboutus/personnel'>Meet your Team</Link>
                </Section>
              </div>
              <div>
                <Section
                  sectionName={`What We've Done`}
                  isOpen={threeExpanded}
                  handleOpen={() => this.toggleMenuExpansion(2)}
                  height='96px'
                >
                  <NavLink to='/case-studies/multi-family'>Case Studies</NavLink>
                  <NavLink to='/map'>Footprint</NavLink>
                </Section>
              </div>
              <div>
                <Section
                  sectionName='Help Hub'
                  isOpen={fourExpanded}
                  handleOpen={() => this.toggleMenuExpansion(3)}
                  height='96px'
                >
                  <NavLink to='/faq'>FAQs</NavLink>
                  <NavLink to='/glossary'>Glossary</NavLink>
                </Section>
              </div>
              <SectionWrap>
                <NavLink to='/contact-us' style={{ fontSize: 14 }}>
                  Contact Us
                </NavLink>
              </SectionWrap>
            </MenuSection>
          </div>
        </FooterWrap>
        <FinePrintWrap>
          <FinePrintInner>
            <div css={{ display: 'flex', alignItems: 'center' }}>
              <NavLink to='/privacy-page'>Privacy Policy</NavLink>
              <div
                css={{
                  color: '#fff',
                  marginLeft: 4,
                  marginRight: 4,
                }}
              >
                |
              </div>
              <NavLink to='/'>Site Map</NavLink>
            </div>
            <Copyright>© {year} by Madison SPECS</Copyright>
          </FinePrintInner>
        </FinePrintWrap>
      </MainWrap>
    );
  }
}

function Section({ sectionName, isOpen, handleOpen, children, height }) {
  return (
    <div css={{ borderBottom: '0.5px solid #fff' }}>
      <SectionWrap onClick={handleOpen}>
        <div>{sectionName}</div>
        <DownArrow css={[{ transition: 'all .2s' }, isOpen && { transform: 'rotate(-180deg)' }]} />
      </SectionWrap>
      <MenuItemWrap isOpen={isOpen} height={height}>
        <InnerItemWrap>{children}</InnerItemWrap>
      </MenuItemWrap>
    </div>
  );
}
