import React from 'react';
import styled from '@emotion/styled';

const dsfdf = './curved-bg.svg';

export const Universe = styled.div`
  padding: 20px;
  .greyCircleBgcDesign {
    display: none;
  }
  @media (min-width: 1440px) {
    height: 100vh;
    .greyCircleBgcDesign {
      display: block;
      position: absolute;
      top: 0;
      left: 48px;
      width: calc(100% - 96px);
      height: 100%;
      z-index: 10;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-image: url('https://res.cloudinary.com/dyuuf6gor/image/upload/v1711463505/specs/about/ellipse16_fbxaho.png');
    }
  }
`;

export const PlanetCoreWrap = styled.div`
  height: auto;
  width: 100%;
  border-radius: 20px;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  padding-top: 45px;
  box-shadow: 0px 0px 32px rgba(3, 24, 198, 0.2);
  .closeIcon {
    position: fixed;
    top: 32px;
    right: 32px;
    cursor: pointer;
    z-index: 500;
  }
  @media (min-width: 1440px) {
    padding: 0px;
    top: 50%;
    right: 0px;
    transform: translate( 0%, -50%);
    height: calc(100vh - 48px);
    max-width: calc(100vw - 48px);
    padding-left: 120px;
    padding-right: 120px; 
    overflow: auto;
    .closeIcon {
      top: 15px;
      right: 15px;
      position: absolute;
    
    }
  }
  @media (min-width: 1920px) {
    padding: 0 100px;
  }
`;

export const ExpandedCircleWrap = styled.div`
  position: relative;
  height: 100%;
  /* background-image: url('https://res.cloudinary.com/dyuuf6gor/image/upload/v1711640428/specs/about-us/curved-bg_d7hyav.png'); */
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  padding: 8px 0px 0px;
  display: flex;
  flex-direction: column;
  z-index: 12;
  .innerContent {
    background-color: #f7f7f7;
    padding-bottom: 40px;
    padding-top: 28px;
  }
  .bgc-title, .plusOne, .plusTwo, .plusThree, .plusFour {
    display: none;
  }
  .mini-desc {
    color: #9c9c9c;
    text-align: center;
    font-family: Ubuntu;
    font-size: 17px;
    font-style: normal;
    font-weight: 300;
    line-height: 28px;
    margin-bottom: 16px;
    padding: 0 20px;
  }
  .personWrap {
    font-size: 24px;
    color: #000;
    margin-bottom: 32px;
    text-align: center;
    div:first-of-type {
      font-family: Syne;
    }
    div:last-of-type {
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      color: #9c9c9c;
      margin-top: 10px;
    }
    :last-of-type {
      padding-bottom: 0px;
      margin-bottom: 0;
    }
  }
  @media (min-width: 1440px) {
    /* border-top: none;
    background: none; */
    padding-bottom: 0;
    justify-content: center;
    align-items: center;
    padding: 64px 64px;

    .innerContent {
      background-color: transparent;
      padding: 0;
    }
    .newSquare {
      position: absolute;
    }
    .plusOne {
      position: absolute;
      left: 0;
      top: 30px;
      display: block;
    }
    .plusTwo {
      position: absolute;
      right: 20px;
      top: 150px;
      display: block;
    }
    .plusThree {
      display: block;
      position: absolute;
      bottom: 30px;
      right: 50px;
    }
    .plusFour {
      display: block;
      position: absolute;
      bottom: 20px;
      left: 56px;
    }
    .kippah {
      display: none;
    }
    .bgc-title {
      display: block;
      position: absolute;
      top: 56px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 50px;
      font-family: Syne;
      font-size: 180px;
      line-height: 160px;
      color: rgba(237, 237, 243, 0.60);
      text-align: center;
    }
    .personsListWrap {
      display: flex;
      flex-wrap: wrap;
      max-width: 1100px;
      margin: 0 auto;
      position: relative;
      z-index: 15;
    }
    .personWrap {
      width: 50%;
      text-align: left;
      font-size: 24px;
      margin-bottom: 16px;
      div:last-of-type {
        font-size: 14px;
        margin-top: 5px;
      }
      :nth-of-type(even) {
        text-align: right;
      }
      :last-of-type {
        padding-bottom: 0px;
      }
    }
    .mini-desc {
      align-items: center;
      background-color: #EDEDF3;
      border-radius: 50%;
      color: #000;
      display: flex;
      font-size: 20px;
      height: 402px;
      justify-content: center;
      left: 50%;
      line-height: 28px;
      margin: 0px;
      max-width: 402px;
      padding: 50px 52px 20px;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 450px;
      z-index: 55;
    }
  }
`;

export function BGCCircle({ ...props }) {
  return (
    <svg
      width='390'
      height='821'
      viewBox='0 0 390 821'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <circle cx='194.5' cy='410.5' r='410' fill='url(#paint0_linear_66_595)' stroke='#DCDCDC' />
      <defs>
        <linearGradient
          id='paint0_linear_66_595'
          x1='-290.162'
          y1='-95.5744'
          x2='315.665'
          y2='606.349'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F2F2F2' />
          <stop offset='0.86' stopColor='white' />
          <stop offset='1' stopColor='white' />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function DTCircle({ ...props }) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={1572} height={1080} fill='none' {...props}>
      <circle cx={786} cy={540} r={785.5} fill='url(#a)' stroke='#DCDCDC' />
      <defs>
        <linearGradient
          id='a'
          x1={-142}
          x2={1018}
          y1={-429}
          y2={915}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F2F2F2' />
          <stop offset={0.86} stopColor='#fff' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function GreenPlus({ ...props }) {
  return (
    <svg
      width='66'
      height='66'
      viewBox='0 0 66 66'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M35.7425 19.0448C35.6375 17.6218 34.4497 16.5 33 16.5C31.4812 16.5 30.25 17.7312 30.25 19.25V30.25H19.25L19.0448 30.2575C17.6218 30.3625 16.5 31.5503 16.5 33C16.5 34.5188 17.7312 35.75 19.25 35.75H30.25V46.75L30.2575 46.9552C30.3625 48.3782 31.5503 49.5 33 49.5C34.5188 49.5 35.75 48.2688 35.75 46.75V35.75H46.75L46.9552 35.7425C48.3782 35.6375 49.5 34.4497 49.5 33C49.5 31.4812 48.2688 30.25 46.75 30.25H35.75V19.25L35.7425 19.0448Z'
        fill='#55DCAC'
      />
    </svg>
  );
}

export function CloseIcon({ ...props }) {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <circle cx='16' cy='16' r='16' fill='white' />
      <line x1='10.3536' y1='9.64645' x2='22.3536' y2='21.6464' stroke='#0318C6' />
      <line
        y1='-0.5'
        x2='16.9706'
        y2='-0.5'
        transform='matrix(-0.707107 0.707107 0.707107 0.707107 22 10)'
        stroke='#0318C6'
      />
    </svg>
  );
}

export function CurvedBG({ ...props }) {
  return (
    <svg
      width='360'
      height='1182'
      viewBox='0 0 360 1182'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='curvedBG'
      {...props}
    >
      <g filter='url(#filter0_b_437_294)'>
        <path
          d='M-5.51623 653.894C-5.51626 653.894 -5.5162 653.894 -5.51623 653.894L-5.51613 1170.84C-5.51613 1181.11 3.43818 1189.44 14.4839 1189.44H345.516C356.562 1189.44 365.516 1181.11 365.516 1170.84V42.4278C310.218 15.7396 246.861 0.582031 179.524 0.582031C112.573 0.582031 49.5569 15.5664 -5.51623 41.9702V653.894Z'
          fill='url(#paint0_linear_437_294)'
          fill-opacity='0.89'
        />
        <path
          d='M-5.01623 654.394V653.894V42.2851C49.9221 15.9982 112.763 1.08203 179.524 1.08203C246.671 1.08203 309.852 16.171 365.016 42.7418V1170.84C365.016 1180.8 356.32 1188.94 345.516 1188.94H14.4839C3.67966 1188.94 -5.01613 1180.8 -5.01613 1170.84L-5.01623 654.394Z'
          stroke='#DCDCDC'
        />
      </g>
      <defs>
        <filter
          id='filter0_b_437_294'
          x='-28.5162'
          y='-22.418'
          width='417.032'
          height='1234.86'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='11.5' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_437_294' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_backgroundBlur_437_294'
            result='shape'
          />
        </filter>
        <linearGradient
          id='paint0_linear_437_294'
          x1='18'
          y1='-4.99947'
          x2='351.08'
          y2='1946.87'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F2F2F2' />
          <stop offset='0.86' stopColor='white' />
          <stop offset='1' stopColor='white' />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function NewCircle() {
  return (
    <svg
      width='1572'
      height='1080'
      viewBox='0 0 1572 1080'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='786' cy='540' r='785.5' fill='url(#paint0_linear_195_133)' stroke='#DCDCDC' />
      <defs>
        <linearGradient
          id='paint0_linear_195_133'
          x1='-142'
          y1='-429'
          x2='1018'
          y2='915'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F2F2F2' />
          <stop offset='0.86' stopColor='white' />
          <stop offset='1' stopColor='white' />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function Yarmulke({ ...props }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlSpace='preserve'
      // style={{ enableBackground: 'new 0 0 368.6 41.8' }}
      viewBox='0 0 368.6 41.8'
      {...props}
    >
      <path
        d='M369.6 42.4C314.3 15.7 250.9.6 183.6.6c-67 0-130 15-185 41.4'
        style={{ fill: '#F7F7F7' }}
      />
      <path
        d='M-.9 42.3C54 16 116.9 1.1 183.6 1.1c67.1 0 130.3 15.1 185.5 41.7'
        style={{
          fill: 'none',
          stroke: '#dcdcdc',
        }}
      />
    </svg>
  );
}
