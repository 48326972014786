/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';

import { nameFinder, findPerson } from './ui/salePeeps';

import Header from './layout/Header';
import Footer from './layout/Footer';
import MobileFooter from './layout/MobileFooter';
import MobileHeader from './layout/MobileHeader';
import MobileSalesPersonCard from './ui/SalesPersonCard';

const Layout = ({ children, noHeader, ...props }) => {
  const showFullSmartTour =
    props.location.pathname === '/' ||
    props.location.pathname === '' ||
    props.location.pathname === '/costseg-at-a-glance/' ||
    props.location.pathname === '/costseg-at-a-glance' ||
    props.location.pathname === '/why-madison-specs' ||
    props.location.pathname === '/why-madison-specs/';
  const noShow =
    props.location.pathname === '/cost-segregation-quote' ||
    props.location.pathname === '/feasibility-analysis' ||
    props.location.pathname === '/custom-quote';

  return (
    <Fragment>
      {!noHeader && (
        <header css={{ position: 'relative', zIndex: '400' }}>
          <MobileHeader />
          <Header />
        </header>
      )}
      {!noHeader ? (
        <main css={{ position: 'relative', zIndex: '1' }}>{children}</main>
      ) : (
        <div css={{ position: 'relative', zIndex: '1' }}>{children}</div>
      )}
      <div>{nameFinder() && <MobileSalesPersonCard noShow={noShow} person={findPerson()} />}</div>
      <footer>
        <MobileFooter />
        <Footer />
      </footer>
    </Fragment>
  );
};

// Layout.propTypes = {
//   children: PropTypes.node.isRequired,
// };

export default Layout;
