import React from 'react';

export const categories = [
  {
    id: 1,
    title: (
      // eslint-disable-next-line react/react-in-jsx-scope
      <>
        Accounting
        <br /> Team
      </>
    ),
    description: `When it comes to working the numbers, you want the very best minds to be behind your study. Our accounting team is well-versed in the ins and outs of the IRS requirements, so you can be sure you're getting the maximum savings every time.`,
    items: [
      {
        one: 'Chuck Tover',
        two: 'Tax Director',
      },
      {
        one: 'Ari Lasky',
        two: 'Director of Tax Advisory',
      },
      {
        one: 'Shlomo Laufer',
        two: 'Senior Tax Associate',
      },
      {
        one: 'Rachel Rosenblatt',
        two: 'Tax Associate',
      },
      {
        one: 'Rachel Muelgay',
        two: 'Tax Associate',
      },
      {
        one: 'Goldie Lieber',
        two: 'Tax Associate',
      },
      {
        one: 'Miriam Franco',
        two: 'Tax Associate',
      },
      {
        one: 'Aliza Weiss',
        two: 'Tax Associate',
      },
      {
        one: 'Brocha Spliman',
        two: 'Tax Associate',
      },
    ],
    get qty() {
      return this.items.length;
    },
  },
  {
    id: 2,
    title: 'Account Executives',
    get qty() {
      return this.items.length;
    },
    description: `Our account executives are the behind-the-scenes engine that keep your study moving along smoothly. From answering questions to providing critical support, these are the experts you want to have overseeing every detail of your study process.`,
    items: [
      {
        one: 'Moshe Aronov',
        two: 'Cost Segregation Specialist',
      },
      {
        one: 'Devora Berger',
        two: 'Cost Segregation Specialist',
      },
      {
        one: 'Chaya Buchler',
        two: 'Cost Segregation Specialist',
      },
      {
        one: 'Maureen Denzler',
        two: 'Cost Segregation Specialist',
      },
      {
        one: 'Mindy Edeltuch',
        two: 'Client Support Manager',
      },
      {
        one: 'Jacob Frager',
        two: 'Cost Segregation Specialist',
      },
      {
        one: 'Hendy Friedman',
        two: 'CPA Support Manager',
      },
      {
        one: 'Yehudit Goodman',
        two: 'Accounts Receivable Specialist',
      },
      {
        one: 'Rosie Greenbaum',
        two: 'Cost Segregation Specialist',
      },
      {
        one: 'Rivka Harnik',
        two: 'Cost Segregation Specialist',
      },
      {
        one: 'Goldie Hartman',
        two: 'Client Support Specialist',
      },
      {
        one: 'Hindy Herskovich',
        two: 'Client Support Manager',
      },
      {
        one: 'Dina Levy',
        two: 'Cost Segregation Specialist',
      },
      {
        one: 'Chana Leba Lipschutz',
        two: 'VP of Operations',
      },
      {
        one: 'Shifra Mandelbaum',
        two: 'Cost Segregation Specialist',
      },
      {
        one: 'Kaila Minzberg',
        two: 'Client Support Manager',
      },
      {
        one: 'Esther Olshin',
        two: 'Accounts Receivable Specialist',
      },
      {
        one: 'Miriam Robinson',
        two: 'Accounts Receivable Specialist',
      },
      {
        one: 'Esther Schachner',
        two: 'Senior Cost Segregation Specialist',
      },
      {
        one: 'Rochi Shlesinger',
        two: 'Accounts Receivable Specialist',
      },
      {
        one: 'Rivky Welz',
        two: 'Cost Segregation Specialist',
      },
      {
        one: 'Chana Pearl',
        two: 'Client Support Manager',
      },
      {
        one: 'Yoni Meltzer',
        two: 'Cost Segregation Specialist',
      },
      {
        one: 'Miriam Millman',
        two: 'Accounts Receivable Specialist',
      },
    ],
  },
  {
    id: 3,
    title: 'Engineering Professionals',
    get qty() {
      return this.items.length;
    },
    description: `Our Engineering Professionals are located across the country, ready to perform your study when you need it. With dozens of years of cumulative experience, our team can (and do!) spot the little details that make a big difference in your tax bill.`,
    items: [
      {
        one: 'Tom Varney',
        two: 'Director of Engineering',
      },
      {
        one: 'Mario Bertinelli',
        two: 'Engineering Manager',
      },
      {
        one: 'Sam Sharaby',
        two: 'Senior Cost Segregation Engineer',
      },
      {
        one: 'Ephraim Baruch',
        two: 'Senior Cost Segregation Engineer',
      },
      {
        one: 'Eli Terebelo',
        two: 'Cost Segregation Engineer',
      },
      {
        one: 'Benjamin Szuchmacher',
        two: 'Cost Segregation Engineer',
      },
      {
        one: 'Mendel Shulman',
        two: 'Cost Segregation Engineer',
      },
      {
        one: 'Avigdor Schultz',
        two: 'Cost Segregation Engineer',
      },
      {
        one: 'Tzvi Lerner',
        two: 'Cost Segregation Engineer',
      },
      {
        one: 'Mike Seelof',
        two: 'Cost Segregation Engineer',
      },
      {
        one: 'Avigail Stein',
        two: 'Cost Segregation Engineer',
      },

      {
        one: 'Miri Weinberg',
        two: 'Cost Segregation Engineer',
      },
      {
        one: 'Ita Tapick',
        two: 'Cost Segregation Engineer',
      },
      {
        one: 'Ravit Friedman',
        two: 'Cost Segregation Engineer',
      },
      {
        one: 'Rivki Averbuch',
        two: 'Cost Segregation Engineer',
      },
      {
        one: 'Shira Perez',
        two: 'Cost Segregation Engineer',
      },
      {
        one: 'Chani Heftler',
        two: 'Cost Segregation Engineer',
      },
      {
        one: 'Shlomo Heimlich',
        two: 'Cost Segregation Engineer',
      },
      {
        one: 'Suri Illowitz',
        two: 'Cost Segregation Engineer',
      },
      {
        one: 'Menchem Gewirtzman',
        two: 'Cost Segregation Engineer',
      },
      {
        one: 'Miri Sheinberg',
        two: 'Cost Segregation Engineer',
      },
    ],
  },
];

export const leaderShips = [
  {
    nameFirst: 'Yonah',
    nameLast: 'Weiss',
    title: 'Regional Business Director',
    linkedIn: 'https://www.linkedin.com/in/cost-segregation-yonah-weiss/',
    twitter: 'https://twitter.com/yonahweiss?lang=en',
    img: 'https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/specs/about/yonah_weiss_bqjt0y.png',
    showTitle: false,
    podcast: 'https://podcasts.apple.com/us/podcast/weiss-advice/id1515387561',
    bigPockets: 'https://www.biggerpockets.com/users/yonahw18',
    facebook: 'https://www.facebook.com/yonah.weiss.1',
    className: 'yonahWrap',
  },
  {
    nameFirst: 'Leo',
    nameLast: 'Rosenbaum',
    title: 'Cost Segregation Specialist',
    linkedIn: 'https://www.linkedin.com/in/leo-rosenbaum-0569499/',
    twitter: '',
    img: 'https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/specs/about/leo_rosenbaum_nsyol3.png',
    showTitle: false,
  },
  {
    nameFirst: 'Brian',
    nameLast: 'Bigham',
    title: 'Cost Segregation Expert',
    linkedIn: 'https://www.linkedin.com/in/costsegcowboy/',
    twitter: 'https://x.com/CostSegCowboy',
    img: 'https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/specs/about/Brian_Bigham_es7szr.png',
    showTitle: false,
    instagram: 'https://www.instagram.com/costsegcowboy/',
    facebook: 'https://www.facebook.com/brian.bigham.9693',
  },
  {
    nameFirst: 'Isaac',
    nameLast: 'Weinberger',
    title: 'Regional Sales Manager',
    linkedIn: 'https://www.linkedin.com/in/isaac-weinberger-b0b749225/',
    twitter: '',
    img: 'https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/specs/about/isaac_weinberger_gpkl9k.png',
    showTitle: false,
    youtube: 'https://www.youtube.com/@CostSegIsaac',
  },
  {
    nameFirst: 'Eden',
    nameLast: 'Markowitz',
    title: 'Cost Segregation Expert',
    linkedIn: 'https://www.linkedin.com/in/eden-markowitz-11869982/',
    twitter: '',
    img: 'https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/specs/about/Eden_Markowitz_ycfvie.png',
    instagram: 'https://www.instagram.com/eden_markowitz/?hl=en',
    facebook: 'https://www.facebook.com/eden.markowitz',
  },
  {
    nameFirst: 'David',
    nameLast: 'Shaya',
    title: 'Cost Segregation Specialist',
    linkedIn: 'https://www.linkedin.com/in/david-shaya-9a9201152/',
    twitter: '',
    img: '',
    showTitle: false,
  },
  // {
  //   nameFirst: 'Mark',
  //   nameLast: 'Amoyelle',
  //   title: 'Regional Business Director',
  //   linkedIn: 'https://www.linkedin.com/in/mark-mordechai-amoyelle-691105a4/',
  //   twitter: '',
  //   img: 'https://res.cloudinary.com/dyuuf6gor/image/upload/v1711480215/specs/about/Rectangle_29_oksptd.png',
  // },
  // {
  //   nameFirst: 'Herschel',
  //   nameLast: 'Bacharach',
  //   title: 'Regional Business Director',
  //   linkedIn: '',
  //   twitter: '',
  //   img: '',
  // },
  // {
  //   nameFirst: 'Howie',
  //   nameLast: 'Berenstein',
  //   title: 'Regional Business Director',
  //   linkedIn: 'https://www.linkedin.com/in/howie-berenstein-2b2091a/',
  //   twitter: '',
  //   img: '',
  // },
  // {
  //   nameFirst: 'Brian',
  //   nameLast: 'Caplan',
  //   title: 'Regional Business Director',
  //   linkedIn: 'https://www.linkedin.com/in/brian-caplan-8352182a/',
  //   twitter: '',
  //   img: '',
  // },
  // {
  //   nameFirst: 'Sean',
  //   nameLast: 'Davis',
  //   title: 'Cost Segregation Specialist',
  //   linkedIn: 'https://www.linkedin.com/in/sean-davis-09a5b0152/',
  //   twitter: '',
  //   img: '',
  // },
  // {
  //   nameFirst: 'Adam',
  //   nameLast: 'Hulkower',
  //   title: 'Cost Segregation Specialist',
  //   linkedIn: 'https://www.linkedin.com/in/adamhulk/',
  //   twitter: '',
  //   img: '',
  // },
  // {
  //   nameFirst: 'Isaac',
  //   nameLast: 'Lev',
  //   title: 'Regional Business Director',
  //   linkedIn: 'https://www.linkedin.com/in/isaac-lev-ba3656152/',
  //   twitter: '',
  //   img: '',
  // },
  // {
  //   nameFirst: 'Donald',
  //   nameLast: 'Reich',
  //   title: 'Cost Segregation Specialist',
  //   linkedIn: 'https://www.linkedin.com/in/donny-reich-87a5324/',
  //   twitter: '',
  //   img: '',
  // },
  // {
  //   nameFirst: 'Charles',
  //   nameLast: 'Rosenbaum',
  //   title: 'Regional Director',
  //   linkedIn: 'https://www.linkedin.com/in/chesky-charles-rosenbaum-98a40a176/',
  //   twitter: '',
  //   img: '',
  // },

  // {
  //   nameFirst: 'Yoni',
  //   nameLast: 'Rosebaum',
  //   title: 'Cost Segregation Specialist',
  //   linkedIn: 'https://www.linkedin.com/in/yoni-rosenbaum-9bb68768/',
  //   twitter: '',
  //   img: '',
  // },
  // {
  //   nameFirst: 'Jason',
  //   nameLast: 'Schreiber',
  //   title: 'Cost Segregation Specialist',
  //   linkedIn: 'https://www.linkedin.com/in/1jasonschreiber/',
  //   twitter: '',
  //   img: '',
  // },

  // {
  //   nameFirst: 'Daniel',
  //   nameLast: 'Sherman',
  //   title: 'Cost Segregation Specialist',
  //   linkedIn: 'https://www.linkedin.com/in/daniel-sherman-917346162/',
  //   twitter: '',
  //   img: '',
  // },
  // {
  //   nameFirst: 'Eli',
  //   nameLast: 'Shicker',
  //   title: '',
  //   linkedIn: '',
  //   twitter: '',
  //   img: '',
  // },
  // {
  //   nameFirst: 'Avi',
  //   nameLast: 'Strasser',
  //   title: 'Cost Segregation Specialist',
  //   linkedIn: 'https://www.linkedin.com/in/avi-strasser-27ab4bb7/',
  //   twitter: '',
  //   img: '',
  // },
  // {
  //   nameFirst: 'Samuel',
  //   nameLast: 'Wolf',
  //   title: 'Regional Business Director',
  //   linkedIn: 'https://www.linkedin.com/in/samuel-wolf-5248b6260/',
  //   twitter: '',
  //   img: '',
  // },
  // {
  //   nameFirst: 'Matt',
  //   nameLast: 'Clark',
  //   title: 'Cost Segregation Specialist',
  //   linkedIn: 'https://www.linkedin.com/in/mczz/',
  //   twitter: '',
  //   img: 'https://res.cloudinary.com/dyuuf6gor/image/upload/v1711480203/specs/about/matt-c_oeanwd.png',
  // },
];
