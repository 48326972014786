/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import {
  addressStyles,
  bigLine,
  branchWrap,
  clientImagesWrap,
  contactDetailStyles,
  goodCompany,
  iconStyles,
  imageStyles,
  innerWrap,
  lovetohearMessage,
  mapStyles,
  offices,
  outerOffices,
  quote,
  smallLine,
  testimonialOverrides,
  textWrap,
  wrapper,
} from '../components/contactUs/contactUsStyles';
import { ReCaptchaContactForm } from '../components/contactUs/ContactForm';
import { MobilePhoneIcon, LetterIcon } from '../components/ui/icons';
import Image from '../components/ui/Image';
import { HubSpotScript } from '../components/ui/hubspotHook';

// ---  THIS CONTACT PAGE IF FOR WHEN LINKED TO FROM FACEBOOK

export default function ContactUsFB() {
  return (
    <Fragment>
      <Helmet>
        <title>Madison SPECS | Contact Us FB</title>
        <meta name='description' content='Contact us Facebook' />
      </Helmet>
      <HubSpotScript />
      <div css={{ position: 'relative', overflow: 'hidden' }}>
        <ReCaptchaContactForm />
        <Testimonial styles={testimonialOverrides} />
        <div css={lovetohearMessage}>
          <div css={textWrap}>
            <div className='syne'>We'd love to hear from you.</div>
          </div>
          <div css={contactDetailStyles}>
            <div css={{ zIndex: 200, position: 'relative' }}>
              <div css={iconStyles}>
                <span css={{ marginRight: 24 }}>
                  <MobilePhoneIcon />
                </span>
                <a href='tel:8887732773'>888-773-2773</a>
              </div>
              <div css={[iconStyles, { marginTop: 24 }]}>
                <span css={{ marginRight: 24 }}>
                  <LetterIcon />
                </span>
                <a href='mailto:info@madisonspecs.com'>info@madisonspecs.com</a>
              </div>
            </div>
            <OfficeLoactions />
          </div>
        </div>
        <Image image='/specs/usa-map_rgv4bs.png' styles={mapStyles} />
      </div>
    </Fragment>
  );
}

function OfficeLoactions() {
  return (
    <div css={outerOffices}>
      <BranchAddress name='Headquarters' address='1125 Ocean Ave' city='Lakewood, NJ 08701' />
      <BranchAddress
        name='Houston Office'
        address='4801 Woodway Drive'
        city='Houston, TX 77056'
        suite='Suite 430W'
      />
      <BranchAddress
        name='Dallas Office'
        address='13101 Preston Road'
        city='Dallas, TX 75240'
        suite='Suite 300'
      />
      <BranchAddress
        name='Ohio Office'
        address='25825 Science Park Drive'
        city='Beachwood, OH 44122'
        suite='Suite 100'
      />
      <BranchAddress
        name='NYC Office'
        address='56 West 45th Street'
        city='New York, NY 10036'
        suite='12th floor'
      />
    </div>
  );
}

function BranchAddress({ name, address, suite, city }) {
  return (
    <div css={branchWrap}>
      <div css={offices}>{name}</div>
      <div css={addressStyles}>
        {address}
        {suite && (
          <Fragment>
            <br />
            {suite}
          </Fragment>
        )}
        <br />
        {city}
      </div>
    </div>
  );
}

export function Testimonial({ styles }) {
  return (
    <div css={[wrapper, styles]}>
      <div css={{ paddingTop: 68, '@media(min-width: 1024px)': { paddingTop: 0 } }}>
        <div css={innerWrap}>
          <div css={imageStyles}>
            <Image image='/specs/testimonials/Gary_A._Taylor_tnzulh.jpg' />
          </div>
          <div css={quote} className='syne'>
            They deliver a quality product in a timely manner. I highly recommend Madison Specs for
            your next cost seg study.
          </div>
          <div css={{ lineHeight: 1.7, marginTop: 16 }}>Gary Taylor</div>
          <div css={{ lineHeight: 1.7 }}>Gary A. Taylor Investment Company</div>
          <div css={{ position: 'relative' }}>
            <div css={smallLine} />
            <div css={goodCompany}>You're in good company...</div>
            <div css={bigLine} />
          </div>
          <div css={clientImagesWrap}>
            <Image image='/specs/clients/Nightingale_logo_grvzoe.svg' />
            <Image image='/specs/clients/Crea_RGB_Logo_mejl9e.svg' />
            <Image image='/specs/clients/AmboProperties_Logo_nkvifp.svg' />
            <Image image='/specs/clients/White_Eagle_PG_Logo_v2n41v.png' />
            <Image image='/specs/cpa/Bernath_Rosenberg_Logo-01_qsg530.svg' />
          </div>
        </div>
      </div>
    </div>
  );
}
