import React from 'react';

import { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import Layout from './components/layout';
import ScrollToTop from './components/ui/ScrollToTop';
import Home from './pages/Home';
import ContactUs from './pages/ContactUs';
import ContactUsFB from './pages/ContactUsFB';
import PrivacyPage from './pages/PrivacyPage';
import FourOhFour from './pages/404';
import ProcessPage from './pages/Process';
import AtAGlance from './pages/AtAGlance';
import WhyMadisonSpecs from './pages/WhyMadisonSpecs';
import AboutUs from './pages/AboutUs';
import Map from './pages/Map';
import Faq from './pages/Faq';
import CaseStudies from './pages/CaseStudies';
import Glossary from './pages/Glossary';
import LeadershipImages from './pages/LeadershipImages';
import OurMission from './pages/OurMission';
import Team from './pages/Team';
import FaqsPdf from './pages/FaqsPdf';
import CustomQuote from './components/custom-quote';
import Form3115 from './components/form3115';

export default class Routes extends Component {
  render() {
    const routes = [
      { path: '/', exact: true, component: Home },
      { path: '/contact-us', exact: true, component: ContactUs },
      { path: '/contact-us/fb', exact: true, component: ContactUsFB },
      { path: '/contact-us-fb', exact: true, component: ContactUsFB },
      { path: '/team', exact: true, component: Team },
      { path: '/about-cost-seg', exact: true, component: AboutUs },
      { path: '/case-studies', component: CaseStudies },
      { path: '/why-madison-specs', exact: true, component: WhyMadisonSpecs },
      { path: '/process', exact: true, component: ProcessPage },
      { path: '/glossary', exact: true, component: Glossary },
      { path: '/costseg-at-a-glance', exact: true, component: AtAGlance },
      { path: '/map', exact: true, component: Map },
      { path: '/faq', exact: true, component: Faq },
      { path: '/custom-quote/:salesRepId?', exact: true, component: CustomQuote },
      { path: '/form-3115', exact: true, component: Form3115 },
      { path: '/faqs-pdf', component: FaqsPdf },
      { path: '/privacy-page', exact: true, component: PrivacyPage },
      { path: '/8f7d6c9b2a3e4f1g', component: LeadershipImages },
      { path: '/our-mission-9b2a3e4f1g', component: OurMission },
      { path: '*', exact: true, component: FourOhFour },
      { path: '/404', exact: true, component: FourOhFour },
      { component: FourOhFour },
    ];

    return (
      <Router>
        <ScrollToTop>
          <Switch>
            <Route exact path='/cost-segregation-quote'>
              <Redirect to='/contact-us' />
            </Route>
            <Route exact path='/feasibility-analysis/isaac'>
              <Redirect to='/custom-quote/isaac' />
            </Route>
            <Route exact path='/feasibility-analysis/brian'>
              <Redirect to='/custom-quote/brian' />
            </Route>
            <Route exact path='/feasibility-analysis/yonah'>
              <Redirect to='/custom-quote/yonah' />
            </Route>
            {routes.map(({ path, exact, noLayout, noHeader, component: Component }) => {
              const withLayout = ({ ...props }) => (
                <Layout noHeader={noHeader} {...props}>
                  <Component {...props} />
                </Layout>
              );
              return (
                <Route
                  key={path}
                  path={path}
                  exact={exact}
                  component={noLayout ? Component : withLayout}
                />
              );
            })}
            <Route component={FourOhFour} />
          </Switch>
        </ScrollToTop>
      </Router>
    );
  }
}
