/** @jsx jsx */
import { jsx, Global } from '@emotion/core';
import React, { useState, useEffect, useRef } from 'react';
import { useIntersection } from 'react-use';
import { NavLink } from 'react-router-dom';

import { BuildingOne, GreenPlusIcon, MotifFade } from './illustrations';
import {
  Root,
  Dropdown,
  DropdownButton,
  SubheaderText,
  ContentWrap,
  TabWrap,
  Tab,
  plusWrap,
  DropDownster,
  TabLink,
  StickyHeader,
  MobileMotif,
  PlusIcon,
  DownCarot,
} from './headerStyles';

export const categories = {
  golfCourse: 'Golf Course',
  multiFamily: 'Multifamily',
  nursingHome: 'Nursing Home',
  office: 'Office',
  retail: 'Retail',
  medical: 'Medical',
  wareHouse: 'Warehouse',
  distributionCenter: 'Distribution Center',
  storage: 'Storage',
  restaurant: 'Restaurant',
  autoDealership: 'Auto Dealership',
  bank: 'Bank',
};

export default function Header({ setCategory }) {
  const [current, setCurrent] = useState(categories.multiFamily);
  const [moreOpen, setMoreOpen] = useState(false);

  const handleChange = (filterName) => {
    setCurrent(filterName);
    setCategory(filterName);
  };
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setMoreOpen(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const texter = (current) => {
    if (current === categories.medical && window.innerWidth < 1023) {
      return 'Medical';
    } else if (current === categories.wareHouse && window.innerWidth < 1023) {
      return 'Warehouse';
    } else if (current === categories.distributionCenter && window.innerWidth < 1439) {
      return 'Distribution Center';
    } else if (current === categories.storage) {
      return 'Storage';
    } else if (current === categories.restaurant) {
      return 'Restaurant';
    } else if (current === categories.autoDealership) {
      return 'Dealership';
    } else if (current === categories.bank) {
      return 'Bank';
    } else {
      return 'More';
    }
  };

  const scroll = (current) => {
    window.scroll(0, 500);
    handleChange(current);
  };

  return (
    <>
      <Root>
        <Global
          styles={{
            body: {
              overflow: 'visible',
            },
            html: {
              overflow: 'visible',
            },
          }}
        />
        <MobileMotif>
          <svg
            width='249'
            height='218'
            viewBox='0 0 249 218'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M248.949 -166.263H14.1627L35.3578 -192.808L35.4771 -192.962V-238.925H213.472V-193.133H236.473V-261.983L12.476 -262V-143.188H234.803L213.591 -116.301L213.472 -116.147L200.996 -70.6122L73.2964 -77.7929L35.4771 -117.327H12.476V-47.537H0V-20.4962V89.7812L201.064 192.292V218H249L248.949 -166.263ZM116.498 -24.6428L104.067 -24.49V81.0216L53.0279 55.7505V-51.3525H137.181L116.498 -24.6428ZM200.97 -22.0298L200.853 -21.8751V128.56L200.87 132.82L149.861 106.162V-45.9808L234.014 -49.0504L200.97 -22.0298Z'
              fill='#F6F6F6'
            />
          </svg>
        </MobileMotif>
        <ContentWrap>
          <div>
            <h1 className='page-header__title'>Case Studies</h1>
            <SubheaderText>
              Experience matters. Our in-house engineers spread across the country have performed
              studies in all 50 states.
            </SubheaderText>
          </div>
          <BuildingOne className='building-illus' />
        </ContentWrap>
        <div css={plusWrap}>
          <GreenPlusIcon />
          <GreenPlusIcon />
        </div>
        <div className='huge-motif'>
          <MotifFade />
        </div>
      </Root>
      <StickyHeader>
        <div>
          <MobileDropdown current={current}>
            <NavLink onClick={() => scroll(categories.multiFamily)} to='/case-studies/multi-family'>
              MultiFamily
            </NavLink>
            <NavLink onClick={() => scroll(categories.nursingHome)} to='/case-studies/nursing-home'>
              Nursing Home
            </NavLink>
            <NavLink onClick={() => scroll(categories.office)} to='/case-studies/office'>
              Office
            </NavLink>
            <NavLink onClick={() => scroll(categories.retail)} to='/case-studies/retail'>
              Retail
            </NavLink>
            <NavLink onClick={() => scroll(categories.medical)} to='/case-studies/medical'>
              Medical
            </NavLink>
            <NavLink onClick={() => scroll(categories.wareHouse)} to='/case-studies/warehouse'>
              Warehouse
            </NavLink>
            <NavLink
              onClick={() => scroll(categories.distributionCenter)}
              to='/case-studies/distribution-center'
            >
              Distribution Center
            </NavLink>
            <NavLink onClick={() => scroll(categories.storage)} to='/case-studies/storage'>
              Storage
            </NavLink>
            <NavLink onClick={() => scroll(categories.restaurant)} to='/case-studies/restaurant'>
              Restaurant
            </NavLink>
            <NavLink
              onClick={() => scroll(categories.autoDealership)}
              to='/case-studies/auto-dealer'
            >
              Auto dealer
            </NavLink>
            <NavLink onClick={() => scroll(categories.bank)} to='/case-studies/bank'>
              Bank
            </NavLink>
            <NavLink onClick={() => scroll(categories.golfCourse)} to='/case-studies/golf-course'>
              Golf Course
            </NavLink>
          </MobileDropdown>
          <TabWrap>
            <TabLink
              onClick={() => scroll(categories.multiFamily)}
              activeClassName='active-nav-link'
              to='/case-studies/multi-family'
            >
              MultiFamily
            </TabLink>
            <TabLink
              onClick={() => scroll(categories.nursingHome)}
              activeClassName='active-nav-link'
              to='/case-studies/nursing-home'
            >
              Nursing Home
            </TabLink>
            <TabLink
              onClick={() => scroll(categories.office)}
              activeClassName='active-nav-link'
              to='/case-studies/office'
            >
              Office
            </TabLink>
            <TabLink
              onClick={() => scroll(categories.retail)}
              activeClassName='active-nav-link'
              to='/case-studies/retail'
            >
              Retail
            </TabLink>
            <TabLink
              onClick={() => scroll(categories.medical)}
              activeClassName='active-nav-link'
              to='/case-studies/medical'
              className='tab-hide tab-see'
            >
              Medical
            </TabLink>
            <TabLink
              onClick={() => scroll(categories.wareHouse)}
              activeClassName='active-nav-link'
              to='/case-studies/warehouse'
              className='tab-hide tab-see'
            >
              Warehouse
            </TabLink>
            <TabLink
              activeClassName='active-nav-link'
              to='/case-studies/distribution-center'
              className='tab-hide tab-desktop'
              onClick={() => scroll(categories.distributionCenter)}
            >
              Distribution Center
            </TabLink>
            <TabLink
              onClick={() => scroll(categories.golfCourse)}
              activeClassName='active-nav-link'
              to='/case-studies/golf-course'
            >
              Golf Course
            </TabLink>
            <Tab onClick={() => setMoreOpen(!moreOpen)} ref={wrapperRef} className='more-tab'>
              <PlusIcon />
              <span>{texter(current)}</span>
              <DropDownster isOpen={moreOpen}>
                <div>
                  <NavLink
                    onClick={() => scroll(categories.medical)}
                    to='/case-studies/medical'
                    className='landscape-hide'
                  >
                    Medical
                  </NavLink>
                  <NavLink
                    onClick={() => scroll(categories.wareHouse)}
                    to='/case-studies/warehouse'
                    className='landscape-hide'
                  >
                    Warehouse
                  </NavLink>
                  <NavLink
                    onClick={() => scroll(categories.distributionCenter)}
                    to='/case-studies/distribution-center'
                    className='desktop-hide'
                  >
                    Distribution Center
                  </NavLink>
                  <NavLink onClick={() => scroll(categories.storage)} to='/case-studies/storage'>
                    Storage
                  </NavLink>
                  <NavLink
                    onClick={() => scroll(categories.restaurant)}
                    to='/case-studies/restaurant'
                  >
                    Restaurant
                  </NavLink>
                  <NavLink
                    onClick={() => scroll(categories.autoDealership)}
                    to='/case-studies/auto-dealer'
                  >
                    Auto Dealer
                  </NavLink>
                  <NavLink onClick={() => scroll(categories.bank)} to='/case-studies/bank'>
                    Bank
                  </NavLink>
                </div>
              </DropDownster>
            </Tab>
          </TabWrap>
        </div>
      </StickyHeader>
    </>
  );
}

function MobileDropdown({ children, current, styles }) {
  const [isOpen, setOpen] = useState(false);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div css={{ position: 'absolute', width: 'calc(100% - 48px)' }}>
      <Dropdown ref={wrapperRef} isOpen={isOpen} onClick={() => setOpen(!isOpen)} css={styles}>
        <DropdownButton>
          {current}
          <DownCarot />
        </DropdownButton>
        <div className='mobile-dropdown'>
          {React.Children.map(children, (child, index) => (
            <div key={index}>{child}</div>
          ))}
        </div>
      </Dropdown>
    </div>
  );
}
